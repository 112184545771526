<template>
  <ShopComponent></ShopComponent>
  <Footer></Footer>
</template>

<script>
// @ is an alias to /src
import ShopComponent from "@/components/ShopComponent.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Shop",
  components: {
    ShopComponent,
    Footer
  }
};
</script>

<style scoped></style>
