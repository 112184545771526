<template>
  <div>
    <p class="email">
      ORDER PRODUCTS AT: <br />
      <a id="link" href="mailto:contact@fixated.nl">contact@fixated.nl</a>
    </p>
    <div class="flex-container">
      <!-- item zero -->
      <div class="item flex-item" id="item-one">
        <!-- carousel -->
        <div
          id="carouselExampleIndicators0"
          class="carousel slide two"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators0"
              data-slide-to="0"
              class="active"
            ></li>
            <li
              data-target="#carouselExampleIndicators0"
              data-slide-to="1"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../assets/sprocket-01.png"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../assets/sprocket-02.png"
                alt="Second slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators0"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators0"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- end carousel -->
        <div class="flex-card">
          <div class="left">
            <h3 class="title-item">Fixated - Stout Sprocket</h3>
            <p class="description-item">
              Black
              <br />
              25t, 19mm, 22mm & 24mm
              <br />
              4130 Steel
              <br />
              Compatible with most guards (ask for compatibility) 
            </p>
          </div>
          <div class="right">
            <p class="price-item">€90 <span class="small">+ shipping</span></p>
          </div> 
        </div>
      </div>
      <!-- end item one -->
      <!-- item one -->
      <div class="item flex-item" id="item-one">
        <!-- carousel -->
        <div
          id="carouselExampleIndicators1"
          class="carousel slide two"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators1"
              data-slide-to="0"
              class="active"
            ></li>
            <li
              data-target="#carouselExampleIndicators1"
              data-slide-to="1"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../assets/black-02.png"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../assets/black-01.png"
                alt="Second slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators1"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators1"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- end carousel -->
        <div class="flex-card">
          <div class="left">
            <h3 class="title-item">Fixated - Raise The Bar</h3>
            <p class="description-item">
              Glossy black, 7.5" rise, 26" wide <br />
              Chromoly steel
            </p>
          </div>
          <div class="right">
            <p class="price-item">€55 <span class="small">+ shipping</span></p>
          </div>
        </div>
      </div>
      <!-- end item one -->
      <!-- start item two -->
      <div class="item flex-item" id="item-one">
        <!-- carousel 2 -->
        <div
          id="carouselExampleIndicators2"
          class="carousel slide two"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators2"
              data-slide-to="0"
              class="active"
            ></li>
            <li
              data-target="#carouselExampleIndicators2"
              data-slide-to="1"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../assets/silver-02.png"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../assets/silver-01.png"
                alt="Second slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- end carousel -->
        <div class="flex-card">
          <div class="left">
            <h3 class="title-item">Fixated - Raise The Bar</h3>
            <p class="description-item">
              Chrome, 7.5" rise, 26" wide <br />
              Chromoly steel
            </p>
          </div>
          <div class="right">
            <p class="price-item">€65 <span class="small">+ shipping</span></p>
          </div>
        </div>
      </div>
      <!-- end item two -->
      <!-- start item three -->
      <div class="item flex-item" id="item-one">
        <!-- carousel 3 -->
        <div
          id="carouselExampleIndicators3"
          class="carousel slide three"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators3"
              data-slide-to="0"
              class="active"
            ></li>
            <li
              data-target="#carouselExampleIndicators3"
              data-slide-to="1"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../assets/shirt-01.png"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../assets/shirt-02.png"
                alt="Second slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators3"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators3"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- end carousel -->
        <div class="flex-card">
          <div class="left">
            <h3 class="title-item">Fixated - Team jersey</h3>
            <p class="description-item">
              Grey
              <br />
              Sizes available: M, L & XL <br />
              Dry-fit
            </p>
          </div>
          <div class="right">
            <p class="price-item">€35 <span class="small">+ shipping</span></p>
          </div>
        </div>
      </div>
      <!-- end item three -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.carousel-control-prev-icon {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57e20' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57e20' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators {
   filter: invert(100%);
}


#link {
  color: #f57e20;
  font-weight: 100;
  font-size: 1.5em;
}

#link:hover {
  color: white;
}

.container {
  margin-top: 0vh;
  padding: 0vw;
  padding-top: 0vh;
  text-align: left;
  width: 92%;
  margin: 0 auto;
}

.item {
  margin: 0 auto;
  padding: 0;
}

.small {
  font-family: Helvetica;
  font-style: italic;
}

.email {
  font-size: 0.7em;
  line-height: 1.5em;
  text-align: center;
  margin-top: 3vh;
  font-family: Helvetica;
  /* text-transform: uppercase; */
}

.image-item {
  width: 92%;
  height: auto;
}

#item-one {
  margin-top: 5vh;
}

.flex-card {
  display: flex;
  padding: 0px;
  margin: 0 auto;
  list-style: none;
  justify-content: space-between;
  /* border-left: 3px solid rgba(245, 125, 32, 0.4);
  border-right: 3px solid rgba(245, 125, 32, 0.4); */
}

.description-item {
  font-size: 0.7em;
  line-height: 1.1;
  margin-top: 0vh;
  font-family: Helvetica, sans-serif;
}

.title-item {
  /* color: #f57e20; */
  margin-bottom: 0vh;
  margin-top: 1vh;
  font-weight: 500;
  color: #f57e20;
  font-size: 0.9em;
}

.price-item {
  /* font-style: italic; */
  margin-top: 1vh;
  font-size: 0.9em;
  text-align: right;
  text-justify: right;
  line-height: 0.9em;
}

.flex-container {
  display: flex;
  padding: 0vw;
  margin: 0 auto;
  list-style: none;
}

.flex-item {
  width: 100%;
  height: auto;
}

/* .carousel-inner .carousel-item > img {
  -webkit-animation: thing 20s;
  -o-animation: thing 20s;
  animation: thing 20s;
} */
/* @keyframes thing {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
} */

/* desktop and ipad pro*/
@media (min-width: 768px) {
  .flex-container {
    margin-top: 0vh;
    padding: 0;
    padding-top: 0vh;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    list-style: none;
    align-items: left;
    overflow-x: hidden;
    justify-content: space-between;
    /* align-self: flex-start; */
  }

  .email {
    /* margin-left: 6%; */
    font-size: 1em;
    line-height: 1.4em;
  }

  .flex-item {
    width: 45%;
    /* width: 10%; */
    height: auto;
    margin: 1vw;
    flex-grow: 0;
    /* border: 3px solid rgba(245, 125, 32, 0.4); */
    text-align: left;
    padding: 2px 0 0 0;
  }
  .container {
    margin-top: 0vh;
    padding: 0;
    padding-top: 0vh;
    /* text-align: left; */
    width: 100%;
    margin: 0 auto;
  }
}

/* mobile */
@media (max-width: 768px) {
  .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0 auto;
    margin-top: 0vh;
    list-style: none;
  }

  .flex-item {
    /* width: 52vh; */
    width: 90vw;
    height: auto;
    /* border: 3px solid rgba(245, 125, 32, 0.7); */
    text-align: left;
    padding: 2px 0 0 0;
  }
}
</style>
